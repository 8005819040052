import * as React from 'react';
import classNames from 'classnames';

import './Icon.scss';

import { ReactComponent as home } from './icons/icon-home.svg';
import { ReactComponent as arrowLeft } from './icons/icon-arrow-left.svg';
import { ReactComponent as arrowRight } from './icons/icon-arrow-right.svg';
import { ReactComponent as arrowDoubleRight } from './icons/icon-arrow-double-right.svg';
import { ReactComponent as arrowDown } from './icons/icon-arrow-down.svg';
import { ReactComponent as arrowTop } from './icons/icon-arrow-top.svg';
import { ReactComponent as cross } from './icons/icon-cross.svg';
import { ReactComponent as cart } from './icons/icon-cart.svg';
import { ReactComponent as menu } from './icons/icon-menu.svg';
import { ReactComponent as pdf } from './icons/icon-pdf.svg';
import { ReactComponent as shareLink } from './icons/icon-share-link.svg';
import { ReactComponent as request } from './icons/icon-request.svg';
import { ReactComponent as exclamation } from './icons/icon-exclamation.svg';
import { ReactComponent as check } from './icons/icon-check.svg';
import { ReactComponent as magnifier } from './icons/icon-magnifier.svg';
import { ReactComponent as navigationDoors } from './icons/icon-doors.svg';
import { ReactComponent as navigationDoor } from './icons/icon-ddleft.svg';

import { ReactComponent as room } from './icons/icon-room.svg';
import { ReactComponent as glass } from './icons/icon-glass.svg';
import { ReactComponent as frame } from './icons/icon-frame.svg';
import { ReactComponent as handle } from './icons/icon-handle.svg';
import { ReactComponent as рandleOverlay } from './icons/icon-griff.svg';
import { ReactComponent as lock } from './icons/icon-lock.svg';
import { ReactComponent as lockCore } from './icons/icon-lock-core.svg';
import { ReactComponent as finish } from './icons/icon-finish.svg';
import { ReactComponent as dimension } from './icons/icon-dimension.svg';
import { ReactComponent as kidsRoom } from './icons/icon-kids_room.svg';
import { ReactComponent as bathRoom } from './icons/icon-bath_room.svg';
import { ReactComponent as bedRoom } from './icons/icon-bed_room.svg';
import { ReactComponent as kitchenRoom } from './icons/icon-kitchen_room.svg';
import { ReactComponent as livingRoom } from './icons/icon-living_room.svg';
import { ReactComponent as plus } from './icons/icon-plus.svg';
import { ReactComponent as zoomIn } from './icons/icon-loop.svg';
import { ReactComponent as doorLeft } from './icons/icon-door-left.svg';
import { ReactComponent as doorRight } from './icons/icon-door-right.svg';
import { ReactComponent as openingLeft } from './icons/icon-opening-left.svg';
import { ReactComponent as openingRight } from './icons/icon-opening-right.svg';
import { ReactComponent as doorFrameMaterial } from './icons/icon-door-frame-material.svg';
import { ReactComponent as filter } from './icons/icon-filter.svg';
import { ReactComponent as zoomSliderIn } from './icons/icon-zoomIn.svg';
import { ReactComponent as zoomSliderOut } from './icons/icon-zoomOut.svg';
import { ReactComponent as addToBasket } from './icons/icon-addToBasket.svg';
import { ReactComponent as doorList } from './icons/icon-doorList.svg';
import { ReactComponent as barcode } from './icons/icon-barcode.svg';
import { ReactComponent as scan } from './icons/icon-scan.svg';

import { ReactComponent as withLockOff } from './icons/icon-mit-schloss-off.svg';
import { ReactComponent as withLockOn } from './icons/icon-mit-schloss-on.svg';
import { ReactComponent as withoutLockOff } from './icons/icon-ohne-schloss-off.svg';
import { ReactComponent as withoutLockOn } from './icons/icon-ohne-schloss-on.svg';
import { ReactComponent as doorPanelOff } from './icons/icon-turblatt-off.svg';
import { ReactComponent as doorPanelOn } from './icons/icon-turblatt-on.svg';
import { ReactComponent as frameOff } from './icons/icon-zarge-off.svg';
import { ReactComponent as frameOn } from './icons/icon-zarge-on.svg';

import { ReactComponent as notFound } from './icons/icon-404.svg';
import { ReactComponent as union } from './icons/icon-union.svg';
import { ReactComponent as videoOff } from './icons/icon-video-off.svg';
import { ReactComponent as dot } from './icons/icon-dot.svg';
import { ReactComponent as locked } from './icons/icon-locked.svg';
import { ReactComponent as noCamera } from './icons/icon-camera-no-access.svg';
import { ReactComponent as asterisk } from './icons/icon-asterisk.svg';
import { ReactComponent as link } from './icons/icon-link.svg';
import { ReactComponent as trash } from './icons/icon-trash.svg';

const icons = {
  home,
  arrowLeft,
  arrowRight,
  arrowDown,
  arrowTop,
  arrowDoubleRight,
  cross,
  cart,
  menu,
  pdf,
  shareLink,
  request,
  exclamation,
  room,
  glass,
  frame,
  handle,
  рandleOverlay,
  lock,
  lockCore,
  finish,
  dimension,
  kidsRoom,
  bathRoom,
  bedRoom,
  kitchenRoom,
  livingRoom,
  navigationDoors,
  navigationDoor,
  plus,
  doorLeft,
  doorRight,
  zoomIn,
  check,
  magnifier,
  openingLeft,
  openingRight,
  doorFrameMaterial,
  filter,
  zoomSliderIn,
  zoomSliderOut,
  addToBasket,
  doorList,
  withLockOff,
  withLockOn,
  withoutLockOff,
  withoutLockOn,
  doorPanelOff,
  doorPanelOn,
  frameOff,
  frameOn,
  barcode,
  scan,
  notFound,
  union,
  videoOff,
  dot,
  locked,
  noCamera,
  asterisk,
  link,
  trash
};

export type IconName = keyof typeof icons;

interface IProps {
  type: IconName;
  className?: string;
}

const Icon: React.FC<IProps> = ({ type, className }) => {
  const SVGIcon = icons[type];

  return (
    <i className={classNames('icon', className)}>
      <SVGIcon className="icon__svg" />
    </i>
  );
};

export default Icon;
