import React from 'react';

import style from './NotFound.module.scss';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button, { Modifiers } from '../Button/Button';
import { rootStore } from '../../stores';

export class NotFound extends React.Component {
  render() {
    return (
      <div className={style.root}>
        <div className={style.notFoundWrapper}>
          <Icon type="notFound" className={style.icon} />
          <div className={style.text}>
            <Typography className={style.title} weight="bold">
              <FormattedMessage id="not.found.page.text" />
            </Typography>
            <Typography className={style.description} weight="light">
              <FormattedMessage id="not.found.page.description" />
            </Typography>
          </div>
          <Link to="/" className={style.homeLink}>
            <Button
              modifiers={[Modifiers.SECONDARY]}
              value={rootStore.localization.formatMessage('not.found.page.go.home')}
            />
          </Link>
        </div>
      </div>
    );
  }
}
