import * as React from 'react';
import classNames from 'classnames';

import { ReactElement } from 'react';
import { dataCy } from '../../../../cy';

import './Button.scss';

interface IProps {
  href?: string;
  type?: 'button' | 'reset' | 'submit';
  icon?: JSX.Element;
  badgeContent?: string | number;
  value?: string | ReactElement;
  modifiers?: Modifiers[];
  className?: string;
  disabled?: boolean;
  hasBadge?: boolean;
  onClick?: () => void;
}

export enum Modifiers {
  SECONDARY = 'button--secondary',
  WITH_ICON = 'button--with-icon',
  ICON_VIEW = 'button--icon-view',
  HIGHLIGHTED = 'button--highlighted',
  DISABLED = 'button--disabled',
  HAS_BADGE = 'button--has-badge'
}

class Button extends React.Component<IProps> {
  render() {
    const {
      href,
      type,
      icon,
      value,
      modifiers = [],
      className,
      disabled,
      hasBadge,
      badgeContent,
      onClick,
      ...rest
    } = this.props;
    return (
      <>
        {href ? (
          <a
            {...rest}
            href={href}
            className={classNames(
              'button',
              'button--link',
              modifiers,
              {
                [Modifiers.WITH_ICON]: icon && value,
                [Modifiers.ICON_VIEW]: icon && !value,
                [Modifiers.DISABLED]: disabled
              },
              className
            )}
            aria-disabled={disabled}
          >
            <span className="button__icon-wrapper">{icon ? icon : null}</span>
            {value ? value : null}
          </a>
        ) : (
          <button
            {...rest}
            type={type}
            className={classNames(
              'button',
              modifiers,
              {
                [Modifiers.WITH_ICON]: icon && value,
                [Modifiers.ICON_VIEW]: icon && !value,
                [Modifiers.DISABLED]: disabled,
                [Modifiers.HAS_BADGE]: hasBadge
              },
              className
            )}
            onClick={onClick}
            disabled={disabled}
          >
            <span className="button__icon-wrapper">{icon ? icon : null}</span>
            {value ? value : null}
            {hasBadge ? (
              <small
                {...dataCy('badge')}
                className={classNames('button__badge', {
                  'button__badge--empty': badgeContent === undefined
                })}
              >
                {badgeContent}
              </small>
            ) : null}
          </button>
        )}
      </>
    );
  }
}

export default Button;
