import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { rootStore } from '../stores';
import { creatorByEanLink, doorLineLink, notFoundLink } from '../models/routes';
import { DoorShortInfo } from '../stores/JokaRootStore';

interface StateProps {
  loaded: boolean;
  info: DoorShortInfo | null;
}

export class OpenDoorByEanPage extends React.Component<RouteComponentProps<{ ean: string }>, StateProps> {
  state: StateProps = {
    loaded: false,
    info: null
  };

  render() {
    const ean = this.props.match.params.ean;
    if (ean) {
      if (!this.state.loaded) {
        rootStore
          .findShortInfoByEan(ean)
          .then(info =>
            this.setState({
              loaded: true,
              info
            })
          )
          .catch(err => console.error(err));
        return <div>Loading...</div>;
      }
      const info = this.state.info;
      if (!info) {
        console.error(`Invalid EAN = ${ean}`);
        return <Redirect to={doorLineLink()} />;
      }
      return <Redirect to={creatorByEanLink(info.productId, ean)} />;
    }
    console.error('No EAN specified');

    return <Redirect to={doorLineLink()} />;
  }
}
