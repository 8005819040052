import * as React from 'react';
import classNames from 'classnames';

import './Typography.scss';

interface IProps {
  component?: React.ElementType;
  weight?: 'light' | 'bold';
  size?: '10' | '12' | '14' | '16' | '18' | '21' | '32';
  lineHeight?: '15' | '17' | '19' | '22' | '24' | '30' | '32' | '56';
  color?: 'default' | 'white' | 'inherit' | 'primary';
  align?: 'center';
  textTransform?: 'uppercase' | 'capitalize';
  className?: string;
}

class Typography extends React.Component<IProps> {
  render() {
    const {
      children,
      component: Component = 'p',
      weight,
      size,
      lineHeight,
      color,
      align,
      textTransform,
      className,
      ...rest
    } = this.props;

    return (
      <Component
        {...rest}
        className={classNames(
          'typography',
          {
            [`typography_weight-${weight}`]: weight,
            [`typography_size-${size}`]: size,
            [`typography_line-height-${lineHeight}`]: lineHeight,
            [`typography_color-${color}`]: color,
            [`typography_align-${align}`]: align,
            [`typography_text-transform-${textTransform}`]: textTransform
          },
          className
        )}
      >
        {children}
      </Component>
    );
  }
}

export default Typography;
