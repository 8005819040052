export const dataCyAttribute = {
  splashDialog: 'splashDialog',
  splashDialogButton: 'splashDialogButton',
  closeDialog: 'closeDialog',
  doorLineSelector: 'doorLineSelector',
  doorLine: 'doorLine',
  doorLineLink: 'doorLineLink',
  categoryFilter: 'categoryFilter',
  doorFoldFilter: 'doorFoldFilter',
  doorLink: 'doorLink',
  canvas: 'canvas',
  addToCartButton: 'addToCartButton',
  goToCartButton: 'goToCartButton',
  contextMenuButton: 'contextMenuButton',
  overviewToggle: 'overviewToggle',
  copyLinkButton: 'copyLinkButton',
  linkBasketButton: 'linkBasketButton',
  copylinkBasketButton: 'copylinkBasketButton',
  copyCodeBasketButton: 'copyCodeBasketButton',
  badge: 'badge',
  loader: 'loader',
  loaded: 'loaded',
  basketDoorLineName: 'doorLineName',
  basketDoorLineNameCell: 'doorLineNameCell',
  basketDoor: 'door',
  basketDoorName: 'doorName',
  basketDoorRoomName: 'basketDoorRoomName',
  basketDoorWidth: 'basketDoorWidth',
  basketDoorHeight: 'basketDoorHeight',
  basketDoorThickness: 'basketDoorThickness',
  materialSelectorContainer: 'material-selector',
  frameSelectorContainer: 'frame-selector',
  glassSelectorContainer: 'glass-selector',
  handleSelectorContainer: 'handle-selector',
  handlePairSelectorContainer: 'handlePair-selector',
  lockSelectorContainer: 'lock-selector',
  lockCoreSelectorContainer: 'lockCoreSelectorContainer',

  priceHandlePair: 'priceHandlePair',
  priceLockCore: 'priceLockCore',

  selectorItem: 'selectorItem',
  selectorItemText: 'selectorItemText',
  selectedItem: 'selectedItem',
  back: 'back',
  home: 'home',
  cartDoorDelete: 'cartDoorDelete',
  cartDoorEdit: 'cartDoorEdit',
  cartDoorElementDetails: 'cartDoorElementDetails',
  emptyCartPlaceholder: 'emptyCartPlaceholder',
  noDoorsPlaceholder: 'noDoorsPlaceholder',
  resetFilterBtn: 'resetFilterBtn',
  materialNavigationLink: 'materialNavigationLink',
  glassNavigationLink: 'glassNavigationLink',
  frameNavigationLink: 'frameNavigationLink',
  handleNavigationLink: 'handleNavigationLink',
  handlePairNavigationLink: 'handlePairNavigationLink',
  lockNavigationLink: 'lockNavigationLink',
  lockCoreNavigationLink: 'lockCoreNavigationLink',
  optionsNavigationLink: 'optionsNavigationLink',
  overviewNavigationLink: 'overviewNavigationLink',
  backNavigationLink: 'backNavigationLink',
  textField: 'textField',
  roomTextField: 'roomTextField',
  commentTextField: 'commentTextField',
  heightSelect: 'heightSelect',
  widthSelect: 'widthSelect',
  thicknessSelect: 'thicknessSelect',
  dinRadio: 'dinRadio',

  detailsTotalPrice: 'detailsTotalPrice',
  elementDetailsItem: 'elementDetailsItem',
  elementDetailsItemCount: 'elementDetailsItemCount',
  elementDetailsItemPrice: 'elementDetailsItemPrice',

  applyEditedChanges: 'applyEditedChanges',
  cancelEditedChanges: 'cancelEditedChanges',

  planarMaterialInput: 'planarMaterialInput',
  planarFrameMaterialInput: 'planarFrameMaterialInput',
  planarGlassInput: 'planarGlassInput',
  planarFrameInput: 'planarFrameInput',
  planarHandleInput: 'planarHandleInput',
  planarLockInput: 'planarLockInput',
  planarLockCoreInput: 'planarLockCoreInput',
  generateDoorPdfButton: 'generateDoorPdfButton',
  generateBasketPdfButton: 'generateBasketPdfButton',
  homePageSearch: 'homePageSearch'
};
export type DataCyAttribute = keyof typeof dataCyAttribute;
export function dataCy(key?: DataCyAttribute) {
  if (!key) {
    return {};
  }
  const value = dataCyAttribute[key];
  return { 'data-cy': value };
}
